export default [ 
  {
    header: 'Cortavo',
  },
  {
    title: 'Knowledge Base',
    route: 'pages-knowledge-base',
    icon: 'InfoIcon',
  },
  {
    title: 'Client List',
    route: 'cortavo-summary',
    icon: 'PowerIcon',
  },
  {
    header: 'Lookups',
  },
//  {
//    title: 'Ticket Finder',
//    route: 'lookup-ticket',
//    icon: 'SearchIcon',
//  },
  {
    title: 'Order Finder',
    route: 'lookup-order',
    icon: 'PackageIcon',
  },
  {
    title: 'Queue Details',
    route: 'lookup-queue',
    icon: 'ClockIcon',
  },
  {
    header: 'Tech Operations',
  },
  {
    title: 'Ops Dashboard',
    route: 'techops-summary',
    icon: 'PackageIcon',
  }
]
