export default [
  {
    header: 'Dashboards',
  },
  {
    title: 'Home',
    route: 'dashboard-home',
    icon: 'HomeIcon',
  },
]
