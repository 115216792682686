<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT  © {{ new Date().getFullYear() }}
      Aventis Systems | Cortavo
      <span class="d-none d-sm-inline-block">, All rights Reserved</span>
    </span>

    <span class="float-md-right d-none d-md-block">##BUILDVERDEV## v2
    </span>
  </p>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
      timer: 0,
      timer2: 0,
    }
  },
  created() {
    this.$http.get('https://asi-api2.azure-api.net/techs/summary/stats/shippedwtech?key=1ea49cfa0a144e7daf97f1e42601ca05')
    this.$http.get('https://asi-api2.azure-api.net/techs/summary?key=1ea49cfa0a144e7daf97f1e42601ca05')
    this.$http.get('https://asi-api2.azure-api.net/psa/query/company?by=cortavoSummary&key=836715d7f31643618225af5af3141717')
    this.$http.get('https://asi-api2.azure-api.net/hr/time/off?days=5&key=379da4d9018f4d0096e7195ebc853c39')
    this.$http.get('https://asi-api2.azure-api.net/ticket/service/boardStats?key=836715d7f31643618225af5af3141717')
    this.$http.get('https://asi-api2.azure-api.net/kb/categories?key=7d959c93d0ed474abcdf04804842848a')
    this.$http.get('https://asi-api2.azure-api.net/kb/articles/top?key=7d959c93d0ed474abcdf04804842848a')
    this.timer2 = setInterval(() => {
      this.searching = true
      this.$http.get('https://asi-api2.azure-api.net/techs/summary/stats/shippedwtech?key=1ea49cfa0a144e7daf97f1e42601ca05')
      this.$http.get('https://asi-api2.azure-api.net/techs/summary?key=1ea49cfa0a144e7daf97f1e42601ca05')
      this.$http.get('https://asi-api2.azure-api.net/psa/query/company?by=cortavoSummary&key=836715d7f31643618225af5af3141717')
      this.$http.get('https://asi-api2.azure-api.net/hr/time/off?days=5&key=379da4d9018f4d0096e7195ebc853c39')
      this.$http.get('https://asi-api2.azure-api.net/ticket/service/boardStats?key=836715d7f31643618225af5af3141717')
      this.$http.get('https://asi-api2.azure-api.net/kb/categories?key=7d959c93d0ed474abcdf04804842848a')
      this.$http.get('https://asi-api2.azure-api.net/kb/articles/top?key=7d959c93d0ed474abcdf04804842848a')
    }, 300000)
  },
}
</script>
