export default [
  {
    header: 'External Links',
  },
  {
    title: 'PTO Calendar',
    href:
    'https://calendar.google.com/calendar?cid=YXZlbnRpc3N5c3RlbXMuY29tX2dzZHM2djFtMWNhM3M0ZjZubWtmaWlqMmdnQGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20',
    icon: 'CalendarIcon',
  },
]
